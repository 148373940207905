@import "./gral.scss";

.containerDashBoard {
  .containerUsuarios {
    display: flex;
    flex-flow: column nowrap;
    margin: 5px 0 0;
    .titleUsuario {
      color: $color-yellow;
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 20px;
    }
    .lineGray {
      background-color: $color-yellow;
      opacity: 20%;
      height: 3px;
      width: 100%;
      margin-bottom: 20px;
    }
    .containerSubTitleTable {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      align-content: center;
      padding: 5px 70px 5px 25px;
      color: $color-yellow;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
      width: 100%;
      .leftSideTable {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-content: center;
        width: 97%;
        .containerTable {
          width: 330px;
        }
        .containerTableID {
          width: 150px;
        }
        .containerTableMore {
          width: 150px;
          margin-right: 15px;
        }
      }
      .rightSideTable {
        width: 3%;
        color: red;
      }
    }
    .containerAllUsuarios {
      display: flex;
      flex-flow: column nowrap;
      .eachUsuario {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-content: center;
        justify-content: space-evenly;
        padding: 5px 70px 5px 25px;
        background-color: $color-gray;
        border: 0.5px solid rgba(255, 255, 255, 0.068);
        height: 50px;
        border-radius: 2px;
        margin-bottom: 20px;
        color: $color-white;
        font-size: 15px;
        font-weight: 550;
        .leftSideEachUser {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          align-content: center;
          width: 97%;
          .containerTable {
            width: 370px;
          }
          .containerTableId {
            width: 150px;
          }
          .containerTableMore {
            width: 150px;
            margin-right: 15px;
          }
        }
        .rightSideEachUser {
          width: 3%;
          text-align: center;
          color: red;
        }
        &:hover {
          background-color: $color-yellow;
          opacity: 1;
          color: $color-black;
          font-weight: bold;
        }
      }
    }

    a:link,
    a:visited,
    a:active {
      text-decoration: none;
      color: $color-white;
    }
  }
}
.containerFilter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerMasivo {
  &.top {
    padding-top: 0.5rem;
  }
  display: flex;
  .cargadorMasivo {
    background-color: $color-yellow;
    max-width: 350px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 1rem;
    a {
      width: 100%;
      padding: 0.5rem 1rem;
      display: block;
      color: $color-black !important;
      i {
        margin-right: 0.5rem;
      }
    }
    + .cargadorMasivo {
      margin-left: 1rem;
    }
    &:hover {
      cursor: pointer;
      background-color: lighten($color: $color-yellow, $amount: 20%);
    }
  }
}
