@import "./gral.scss";

.containerLoginPage {
  overflow: hidden;
  background-color: $color-black;
  .containerLogIn {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    z-index: 3;
    .containerInputs {
      display: flex;
      flex-flow: column nowrap;
      height: 300px;
      width: 450px;
      .welcome {
        font-weight: 500;
        color: $color-white;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .titleLogin {
        color: $color-yellow;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .inputLogIn {
        background-color: $color-gray;
        border: none;
        border: 1px solid rgba(255, 255, 255, 0.527);
        border-radius: 4px;
        padding: 2px 10px;
        height: 40px;
        margin-bottom: 10px;
        color: rgba(255, 255, 255, 0.781);
      }
      .btnLogIn {
        margin-top: 30px;
        font-size: 14px;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        border: none;
        color: $color-black;
        font-weight: 500;
        background-color: $color-yellow;
        &:hover {
          background-color: $color-black;
          border: 2px solid $color-yellow;
          color: $color-white;
        }
      }
  
    }
  }

  .containerWaves {
    bottom: 0;
    position: relative;
    .wave1 {
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 100%;
      opacity: 20%;
    }
    .wave2 {
      position: absolute;
      z-index: 2;
      bottom: 0;
      width: 100%;
      opacity: 20%;
    }
  }
}
