.containerFormFilter {
    max-width: 300px;
    label {
        color: $color-white;
        &.dark {
            color: $color-black;
        }
    }
    select {
        background-color: $color-black;
        border: 1px solid rgba(255, 255, 255, 0.295);
        color: $color-white;
    }
}

.containerAction {
    z-index: 1;
    > div {
        position: absolute;
        background: $color-black;
        padding: 1rem;
        z-index: 2;
        border: 1px solid rgba(255, 255, 255, 0.295);
        border-radius: 5px;
        display: none;
        top: 15px;
        min-width: 250px;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li,
            a {
                color: $color-white;
                cursor: pointer;
                &:hover {
                    color: $color-yellow
                }
            }
        }
    }
    &:hover {
        position: relative;
        z-index: 4;
        > div {
            z-index: 4;
            display: block;
        }
    }
}

.containerTabs {
    width: 300px;
    border: solid $color-white 1px;
    display: flex;
    div {
        width: 50%;
        text-align: center;
        color: $color-black;
        background-color: $color-yellow;
        padding: 0.5rem 1rem;
        cursor: pointer;
        &.active {
            background-color: darken($color: $color-yellow, $amount: 10%);
        }
    }
}

div.custom-container {
    background-color: #242424;
    margin-top: 1rem;
    min-width: 1200px;
}

svg.custom .node circle {
    fill: #F3F3FF;
    stroke: #2593B8;
    stroke-width: 1.5px;
}

svg.custom .node text {
    font-size: 11px;
    background-color: #444;
    fill: #F4F4F4;
    text-shadow: 0 1px 4px black;
}

svg.custom .node {
    cursor: pointer;
}

svg.custom path.link {
    fill: none;
    stroke: #2593B8;
    stroke-width: 1.5px;
}

.containerAccesos {
    width: 100%;
    div {
        padding-left: 50px;
        span {
            margin-bottom: 1rem;
            color: $color-white;
            padding: 0.5rem 1rem;
            min-width: 150px;
            max-width: 300px;
            display: block;
            cursor: pointer;
            border: solid 1px $color-yellow;
            &.active {
                background: $color-yellow;
                color: $color-black
            }
            &.empresa {
                background: darken($color-yellow, 20%);
                color: $color-black
            }
        }
    }
}
