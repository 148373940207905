@import "./gral.scss";

.containerDashBoard {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 200px);
  margin-left: 200px;
  min-height: 100vh;
  .containerEmpresa {
    display: flex;
    flex-flow: column nowrap;
    margin: 5px 0 0px 0;
    .titleEmpresas {
      color: $color-yellow;
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 20px;
    }
    .lineGray {
      background-color: $color-yellow;
      opacity: 20%;
      height: 3px;
      width: 100%;
      margin-bottom: 20px;
    }

    .containerSubTitleTable {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      align-content: center;

      padding: 5px 70px 5px 25px;
      color: $color-yellow;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
      width: 100%;
      .leftSideEachEmpresa {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-content: center;
        justify-content: space-evenly;
        width: 97%;
        .containerTable {
          width: 330px;
        }
        .containerTableID {
          width: 100px;
        }
        .containerTableMore {
          width: 150px;
          margin-right: 25px;
        }
      }
      .rightSideEachEmpresa {
        width: 3%;
        color: red;
      }
    }
    .containerAllEmpresas {
      display: flex;
      flex-flow: column nowrap;
      .eachEmpresa {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-content: center;
        padding: 5px 70px 5px 25px;
        background-color: $color-gray;
        border: 0.5px solid rgba(255, 255, 255, 0.068);
        height: 50px;
        border-radius: 2px;
        margin-bottom: 20px;
        color: $color-white;
        font-size: 15px;
        font-weight: 550;
        .leftSideEachEmpresa {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          align-content: center;

          width: 97%;
          .containerTable {
            width: 330px;
            .normalButton {
              background-color: transparent;
              border: solid 1px #fff;
              padding: 5px 10px;
              color: #fff;
              font-size: 14px;
            }
            .activeButton {
              background-color: rgb(59, 174, 39);
              border: solid 1px rgb(59, 174, 39);
              padding: 5px 10px;
              color: #000;
              font-size: 14px;
            }
          }
          .containerTableID {
            width: 100px;
          }
          .containerTableMore {
            width: 150px;
            margin-right: 20px;
          }

          a:link,
          a:visited,
          a:active {
            text-decoration: none;
            color: $color-white;
          }

          &:hover {
            cursor: pointer;
          }
        }
        .rightSideEachEmpresa {
          width: 3%;
          text-align: center;
          color: red;
          .containerTableTrash {
            color: red;
          }
          &:hover {
            cursor: pointer;
          }
        }
        &:hover {
          background-color: $color-yellow;
          opacity: 1;
          color: $color-black;
          font-weight: bold;
        }
      }
    }
  }
  .containerAdd {
    border: 3px solid $color-yellow;
    background-color: $color-yellow;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    width: 50px;
    height: 50px;
    right: 30px;
    bottom: 30px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    .imgAdd {
      width: 50px;
      height: 50px;
    }
  }
  .containerDelete {
    border: 3px solid $color-yellow;
    background-color: $color-yellow;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    width: 50px;
    height: 50px;
    right: 100px;
    bottom: 30px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    a {
      color: $color-black;
    }
    .imgAdd {
      width: 50px;
      height: 50px;
    }
  }
}

.normalButton {
  background-color: transparent;
  border: solid 1px #fff;
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
}
.activeButton {
  background-color: rgb(59, 174, 39);
  border: solid 1px rgb(59, 174, 39);
  padding: 5px 10px;
  color: #000;
  font-size: 14px;
}
