$roboto-font-regular-path: "../font/Raleway-Regular/";
$roboto-font-bold-path: "../font/Raleway-Bold/";
$roboto-font-italic-path: "../font/Raleway-Italic/";
$awesome-path: "../font/webfonts/";
@font-face {
  font-family: "Raleway-Regular";
  src: url($roboto-font-regular-path + "Raleway-Regular.eot");
  src: url($roboto-font-regular-path + "Raleway-Regular.eot?#iefix")
      format("embedded-opentype"),
    url($roboto-font-regular-path + "Raleway-Regular.woff2") format("woff2"),
    url($roboto-font-regular-path + "Raleway-Regular.woff") format("woff"),
    url($roboto-font-regular-path + "Raleway-Regular.ttf") format("truetype"),
    url($roboto-font-regular-path + "Raleway-Regular.svg#Raleway-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Bold";
  src: url($roboto-font-bold-path + "Raleway-Bold.eot");
  src: url($roboto-font-bold-path + "Raleway-Bold.eot?#iefix")
      format("embedded-opentype"),
    url($roboto-font-bold-path + "Raleway-Bold.woff2") format("woff2"),
    url($roboto-font-bold-path + "Raleway-Bold.woff") format("woff"),
    url($roboto-font-bold-path + "Raleway-Bold.ttf") format("truetype"),
    url($roboto-font-bold-path + "Raleway-Bold.svg#Raleway-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url("#{$awesome-path}/fa-regular-400.eot");
  src: url("#{$awesome-path}/fa-regular-400.eot?#iefix")
      format("embedded-opentype"),
    url("#{$awesome-path}/fa-regular-400.woff2") format("woff2"),
    url("#{$awesome-path}/fa-regular-400.woff") format("woff"),
    url("#{$awesome-path}/fa-regular-400.ttf") format("truetype"),
    url("#{$awesome-path}/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url("#{$awesome-path}/fa-solid-900.eot");
  src: url("#{$awesome-path}/fa-solid-900.eot?#iefix")
      format("embedded-opentype"),
    url("#{$awesome-path}/fa-solid-900.woff2") format("woff2"),
    url("#{$awesome-path}/fa-solid-900.woff") format("woff"),
    url("#{$awesome-path}/fa-solid-900.ttf") format("truetype"),
    url("#{$awesome-path}/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
