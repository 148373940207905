$color-yellow: #F8CE46;
$color-black: #16171B;
$color-gray: #202125;
$color-white: #FAFAFB;

* {
    margin: 0;
    padding: 0;
    font-family: "Raleway-Regular";
}

.react-datepicker-wrapper {
    width: 100%;
    input {
        width: 100%;
        padding: .5rem 1rem;
    }
}

.modal-title1{
    padding: 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid  #ccc;
    margin: 10px 0;
}