.containerForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: $color-gray;
  padding: 1rem;
  .containerFormBox {
    position: relative;
    width: 50%;
    padding: 0 1rem;
    &.full {
      width: 100%;
    }
    .multiSelect {
      max-height: 500px;
      border: solid 1px $color-gray;
      overflow: auto;
      margin-bottom: 1rem;
      ul {
        list-style-type: none;
        color: $color-white;
        border: 1px solid rgba(255, 255, 255, 0.295);
        border-radius: 5px;
        li {
          padding: 5px;
          font-size: 14px;
          &.active {
            background-color: $color-yellow;
            color: $color-black;
          }
          + li {
            border-top: 1px solid rgba(255, 255, 255, 0.295);
          }
        }
      }
    }
    label {
      color: $color-white;
    }
    textarea,
    input,
    select {
      background-color: $color-black;
      border: 1px solid rgba(255, 255, 255, 0.295);
      color: $color-white;
    }
    .containerFormImg {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      img {
        max-width: 500px;
      }
    }
    span {
      background: $color-black;
      border: 1px solid $color-yellow;
      color: $color-white;
      font-size: 14px;
      min-height: 30px;
      position: absolute;
      text-align: start;
      top: -60px;
      left: 0;
      display: none;
      padding: 1rem;
      &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid $color-yellow;
        border-right: 1px solid $color-yellow;
        background: $color-black;
        left: 50%;
        margin-left: -10px;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    label:hover span {
      display: block;
    }
  }
  .containerFormTitle {
    width: 100%;
    padding: 0 1rem;
    h1 {
      color: $color-yellow;
      font-size: 1.2rem;
    }
  }
  .containerFormButton {
    width: 100%;
    padding: 0 1rem;
    display: flex;
    justify-content: flex-end;
  }
}

.containerFilter {
  display: flex;
  align-items: flex-end;
  h3 {
    color: $color-white;
    font-size: 14px;
    margin-bottom: 10px;
  }
  > div {
    margin-right: 10px;
  }
  button {
    border: solid 1px $color-yellow;
    padding: 10px 5px;
    border: 0;
    background-color: $color-yellow;
    font-family: "Raleway-Regular";
    outline: 0;
    &:hover {
      background-color: lighten($color: $color-yellow, $amount: 10%);
    }
  }
}
