@import "./gral.scss";

.loader {
    transform: translate(-50%, -50%);
    border: 16px solid $color-gray;
    border-radius: 50%;
    border-top: 16px solid $color-yellow;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; 
    animation: spin 2s linear infinite;
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
  }
