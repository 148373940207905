@import "./gral.scss";

.containerContentPage {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding: 0 2rem;
  background-color: $color-black;
  overflow: auto;
}
