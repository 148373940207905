@import "./gral.scss";

.containerDashBoard {
  .containerGroups {
    display: flex;
    flex-flow: column nowrap;
    margin: 5px 0 0px 0;
    .titleGroups {
      color: $color-yellow;
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 20px;
    }
    .lineGray {
      background-color: $color-yellow;
      opacity: 20%;
      height: 3px;
      width: 100%;
      margin-bottom: 20px;
    }
    .containerTableGroups {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      width: 100%;
      height: 45px;
      color: $color-yellow;
      margin-bottom: 15px;
      padding: 5px 50px;
      font-size: 17px;
      font-weight: 700;
      .leftSideTableGroups {
        display: flex;
        flex-flow: row nowrap;
        width: 90%;
        .titleLeftSideTableGroupsID {
          width: 155px;
        }

        .titleLeftSideTableGroups {
          width: 250px;
          margin-right: 50px;
        }
      }
      .rightSideTableGroups {
        width: 10%;
        color: red;
      }
    }
    .containerAllGroups {
      display: flex;
      flex-flow: column nowrap;
      .eachGroups {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        padding: 5px 70px 5px 50px;
        background-color: $color-gray;
        border: 0.5px solid rgba(255, 255, 255, 0.068);
        min-height: 50px;
        border-radius: 2px;
        margin-bottom: 20px;
        color: $color-white;
        font-size: 15px;
        font-weight: 550;
        .leftSideEachGroups {
          display: flex;
          flex-flow: row nowrap;
          width: 92%;
          align-items: center;
          .containerTableID {
            width: 155px;
          }
          .containerSubTableGroupsName {
            width: 335px;
            padding-right: 20px;
          }
          .containerSubTableGroups {
            width: 300px;
          }
        }
        .rightSideEachGroups {
          width: 8%;
          display: flex;
          flex-flow: row nowrap;
          text-align: center;
          color: red;
        }
        &:hover {
          background-color: $color-yellow;
          opacity: 1;
          color: $color-black;
          font-weight: bold;
        }
      }
    }
    a:link,
    a:visited,
    a:active {
      text-decoration: none;
      color: $color-white;
    }
  }
}
