@import "./gral.scss";


.containerDashBoard {
  .containerTopBar {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    .topBarFunct {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      .conatinerImgTopBar {
        width: 20px;
        height: 25px;
        .imgTopBar {
          width: 100%;
        }
      }
      .userNameTopBar {
        margin-left: 10px;
        color: $color-yellow;
        font-size: 14px;
        .subtitle {
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
  }
}
