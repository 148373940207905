@import "./gral.scss";

.containerMenu {
  width: 200px;
  background-color: $color-gray;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 20px 0px;
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  bottom: 0;
  float: left;
  .topSideMenu {
    display: flex;
    flex-flow: column nowrap;
    .containerLogoMenu {
      align-self: center;
      width: 50px;
      height: 50px;
      margin: 10px 0px 40px 0px;
      .logoMenu {
        width: 100%;
      }
    }
    .functionsMenu {
      .eachFunctMenu-active {
        background: linear-gradient(
          90deg,
          rgba(248, 206, 70, 1) 2%,
          rgba(248, 206, 70, 0.1) 2%
        );
      }
      .eachFunctMenu {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding-left: 10px;
        height: 45px;
        margin-bottom: 15px;

        &:hover {
          cursor: pointer;
        }

        .containerIconEachFunct {
          i {
            font-size: 1rem;
            color: $color-white;
          }
          .iconEachFunct {
            width: 100%;
          }
        }
      }
      .titleEachFunctMenu {
        margin: 0 0 0 10px;
        font-size: 14px;
        color: $color-white;
        font-weight: 550;
        padding: 0;
      }
    }
  }
  .bottomSideMenu {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-left: 10px;
    .titleEachFunctMenu {
      margin-left: 10px;
      font-size: 12px;
      color: $color-white;
      font-weight: 550;
      &:hover {
        color: $color-yellow;
        cursor: pointer;
      }
    }
  }
}
